import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import "../Episodes.scss";
import API_URL from "../../../../Helper/base";
import ImageComponent from "../../../../components/Image/ImageComponent";

const ASianEpisodes = ({ Id, image, status, title }) => {
  let id = useParams().id;
  const [watchingEpi, setWatchingEpi] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    WatchingDrama();
  }, [id]);

  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = await axios.get(`${API_URL}api/drama-episodes/${Id}`);
    setLoading(false);
    setWatchingEpi(res.data);
    
    
  }
  const length = watchingEpi.length
 
  return (
    <>
      {!loading && (
        <>
          <div className="episodes">
            <div className="ep">
              <div className="heading">
                <h1>
                  Episodes <span>List</span>
                </h1>
              </div>
              <ul className="List">
                {watchingEpi.map((item, i) => {
                  // console.log(item)
                  return (
                    <>
                      <li className="list-item" key={item.id}>
                        <NavLink
                          to={
                            "/asian/drama-watch/" +
                            `${length}/`+
                            `${item.id}` +
                            `${image}`.replace(
                              "https://files.kissasian.fan/movie/",
                              ""
                            ).replace("-poster.png", "") +
                            `/${status}`
                          }

                        >
                          <div className="image-container">
                            <ImageComponent src={image} title={title} />

                            <span className="tag">{status}</span>
                          </div>
                          <div className="ep-flex ">
                            <p>{item.episode}</p>
                          </div>
                        </NavLink>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ASianEpisodes;
