import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import ANIME_URL from "../../Helper/animeApi";
import "./Watching.scss";
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import Footer from "../../components/Footer/Footer"
import AnimeNav from "../../components/Navigation/AnimeNav";
import ReturnLink from './ReturnLink'
import AnimeDetail from "../Detail/AnimeDetail";

import AnimeRecommend from "../Recommend/AnimeRecommend";
const AnimeWatch = ({ Id, title, image, status }) => {
  const id = useParams().id;
  const totalep = useParams().totalep;
  const EpisodeNo = useParams().episodeNo;
  const [watching, setWatching] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    WatchingDrama();
  }, [id, EpisodeNo]);
  console.log(totalep)


  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = await axios.get(`${ANIME_URL}api/watching/${id}/${EpisodeNo}`);
    setLoading(false);
    setWatching(res.data.results);


    // document.title = res.data.link;
  }
  document.title = watching.map((doc, i) => (

    `${doc.title} - Anisian `
  ))

  return (
    <>
      <div className="A-watching">

        <AnimeNav />
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="watching">
              <div className="left-container">
                <>
                  <div className="watching-container">
                    {watching.map((item, i) => (
                      <>
                        <div className="wrapper" key={item.frame}>
                          <div className="heading">
                            <p>
                              <span>watching</span>/
                              <ReturnLink id={id}  />
                              /episode-{EpisodeNo}
                            </p>
                          </div>
                          <div className="card">
                            <h1>{item.title}</h1>
                            <div className="server-notice">
                              <p>If you get any error message when trying to stream, please Refresh the page or switch to another streaming server.</p>
                            </div>
                            <iframe
                              id="iframe-container"
                              src={item.link}
                              frameBorder="0"
                              allowFullScreen={true}
                              marginWidth="0"
                              marginHeight="0"
                              scrolling="no"
                              loading="lazy"
                            ></iframe>
                            <div className="page-btn">

                              <div className="inner-btn">

                                <Link to={"/anime-watch/" + totalep + "/" + id + "/" + `${+EpisodeNo - 1}`} style={{
                                  display:
                                    EpisodeNo <= 1 ? "none" : "block"
                                }} >

                                  <button disabled={EpisodeNo <= 1} >
                                    <BsArrowLeftShort style={{ fontSize: 35 }} />
                                  </button>
                                </Link>
                                <p>Prev</p>
                              </div>

                              <div className="inner-btn">

                                <Link to={"/anime-watch/" + totalep + "/" + id + "/" + `${+EpisodeNo + 1}`} style={{ display: EpisodeNo >= totalep ? "none" : "block" }} >
                                  <button disabled={EpisodeNo >= totalep}>
                                    <BsArrowRightShort style={{ fontSize: 35 }} />
                                  </button>
                                </Link>
                                <p>Next</p>
                              </div>

                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                    <AnimeDetail />
                  </div>
                </>
              </div>

              <div className="right-container">
                <AnimeRecommend />
              </div>
            </div>
            {/* <Footer/> */}

          </>
        )}
      </div>
    </>
  );
};

export default AnimeWatch;
