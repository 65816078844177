import React from 'react'
import { Link } from 'react-router-dom'

const ReturnLink = ({image,title,id}) => {
  return (
    <>
    {id ? (
      <Link to={"/anime-detail/" + `${id}/` }   >
    {id}
    </Link>
    ): (

    <Link to={"/asian-detail/" + `${image}/` }   >
    {title}
    </Link>
    )}
    </>
  )
}

export default ReturnLink
