import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import "./Episodes.scss";
import ANIME_URL from "../../../Helper/animeApi";
import { FiSearch } from "react-icons/fi";
import ImageComponent from "../../../components/Image/ImageComponent";
const Episodes = ({ Id, title, image, status }) => {
  let id = useParams().id;
  const [watchingEpi, setWatchingEpi] = useState([]);

  const [loading, setLoading] = useState(true);

  const [query, setquery] = useState("");
  const handleChange = (e) => {
    setquery(e.target.value);
  };
  useEffect(() => {
    WatchingDrama();
  }, [id]);

  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = await axios.get(`${ANIME_URL}api/details/${id}`);
    setLoading(false);
    setWatchingEpi(res.data.results);
  }
  document.title = watchingEpi.map((doc, i) => `${title} - ASIANdrama.fun `);
  const myFunction = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("MyList");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };
  return (
    <>
      {/* <AsianNav/> */}

      <div className="episodes">
        <div className="anime-ep">
          {watchingEpi.map((item, i) => (
            <>
              <div className="episodes-wrapper" key={i + 1}>
                <div className="heading">
                  <h1>
                    Episodes <span>List</span>
                  </h1>
                </div>
                <div className="episode-search">
                  <input
                    type="text"
                    id="myInput"
                    onKeyUp={myFunction}
                    placeholder="Search Episodes.."
                    title="Search Episodes"
                  />
                  <span className="ep-search">
                    <FiSearch />
                  </span>
                </div>
                <motion.ul
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  className=" List unorder-list"
                  id="MyList"
                >
                  {Array.from({ length: item.totalepisode }, (q, i) => (
                    <motion.li
                      layout
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                      exit={{ opacity: 0 }}
                      className="list-item"
                      id={i + 1}
                    >
                      <NavLink to={"/anime-watch/" + item.totalepisode +"/" + Id + "/" + (i + 1)}>
                        <div className="image-container">
                          <ImageComponent src={image} title={title} />
                          <span className="tag">{status}</span>
                        </div>

                        <div className="ep-flex ">
                          <p>
                            {title} <span>Ep-{i + 1}</span>
                          </p>
                        </div>
                      </NavLink>
                    </motion.li>
                  ))}

                  {/* <div className="md">

                      {Array.from({ length: item.totalepisode }, (q, i) => (
                        <li>
                          <NavLink to={"/anime-watch/" + Id + "/" + (i + 1)}>
                            
                            <div className="ep-flex ani-ep">
                            
                              
                              <p>{i + 1}</p>
                            </div>
                              
                          </NavLink>
                        </li>
                      ))}
                      </div> */}
                </motion.ul>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Episodes;
