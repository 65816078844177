const API_URL = process.env.REACT_APP_ASIAN_DRAMA_URL



export default API_URL





