import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import API_URL from "../../Helper/base";
import Recommend from "../Recommend/AsianRecommend";
import Footer from "../../components/Footer/Footer"
import "./Watching.scss";

import AsianNav from "../../components/Navigation/AsianNav";

import AsianWatchingEp from "./Episodes/Asian/AsianWatchingEp";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import ReturnLink from "./ReturnLink";

const Watching = () => {
  let id = useParams().id;
  let image = useParams().image;
  let status = useParams().country;
  let length = useParams().length;

  const [watchingEpi, setWatchingEpi] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    WatchingDrama();
  }, [id]);

  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = await axios.get(`${API_URL}api/drama-watch/${id}`);
    setLoading(false);
    setWatchingEpi(res.data.watch);

    // document.title = `${res.data.watch.title}`
  }
  document.title = watchingEpi.map((doc, i) => (

    `${doc.title} - Anisian `
  ))
  return (
    <>
      <div className="A-watching">

        <AsianNav />
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="watching">
              <div className="left-container">
                <>
                  <div className="watching-container">
                    {watchingEpi.map((item, i) => {
                      const epNo = id.slice(image.length + 9)
                      const slice = `${item.title}`.length
                      const headingTitle = `${id}`.slice(slice).replace(/-/g, " ")
                      const WatchTitleEpNo = `${id}`.slice(slice)
                      return (
                        <>
                          <div className="wrapper" key={item.frame}>
                            <div className="heading">
                              <p>
                                <span>watching</span>/

                                <ReturnLink image={image} title={item.title} />
                                /e{WatchTitleEpNo}
                              </p>


                            </div>
                            <div className="card">
                              <h1>{item.title} E{headingTitle}</h1>
                              <div className="server-notice">
                                <p>If you get any error message when trying to stream, please Refresh the page or switch to another streaming server.</p>
                              </div>
                              <iframe
                                id="iframe-container"
                                src={item.frame}
                                frameBorder="0"
                                allowFullScreen={true}
                                marginWidth="0"
                                marginHeight="0"
                                scrolling="no"
                                loading="lazy"
                                height={"100%"}
                                width={"100%"}
                                title={item.title}

                              ></iframe>
                              <div className="page-btn">

                                <div className="inner-btn">

                                  <Link to={
                                    "/asian/drama-watch/" + `${length}/` +
                                    `${image}-episode-${+epNo - 1}/` +
                                    `${image}` +
                                    `/${status}`
                                  } style={{
                                    display:
                                      epNo <= 1 ? "none" : "block"
                                  }}  >

                                    <button  >
                                      <BsArrowLeftShort style={{ fontSize: 35 }} />
                                    </button>
                                  </Link>
                                  <p>Prev</p>
                                </div>

                                <div className="inner-btn">

                                  <Link to={
                                    "/asian/drama-watch/" + `${length}/` +
                                    `${image}-episode-${+epNo + 1}/` +
                                    `${image}` +
                                    `/${status}`
                                  } style={{ display: +epNo >= length ? "none" : "block" }}  >
                                    <button >
                                      <BsArrowRightShort style={{ fontSize: 35 }} />
                                    </button>
                                  </Link>
                                  <p>Next</p>
                                </div>

                              </div>

                            </div>
                          </div>
                        </>
                      )
                    })}
                    <AsianWatchingEp image={image} length={length} />
                  </div>
                </>
              </div>

              <div className="right-container">
                <Recommend />
              </div>
            </div>
            {/* <Footer /> */}
          </>
        )}
      </div>
    </>
  );
};

export default Watching;
