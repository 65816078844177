import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import "../Episodes.scss";
import API_URL from "../../../../Helper/base";
import ImageComponent from "../../../../components/Image/ImageComponent";

const AsianWatchingEp = () => {
  let id = useParams().id;
  let image = useParams().image;
  let country = useParams().country;
  let length = useParams().length;
  let src = `https://files.kissasian.fan/movie/${image}-poster.png`;
  const [watchingEpi, setWatchingEpi] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    WatchingDrama();
  }, [id]);

  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = await axios.get(`${API_URL}api/drama-episodes/${id}`);
    setLoading(false);
    setWatchingEpi(res.data);

    document.title = "";
  }

  return (
    <>
      {/* <AsianNav/> */}
      {/* {loading && <Loader />} */}
      {!loading && (
        <>
          <div className="episodes">
            <div className="asian-ep">
              <div className="heading">
                <h1>
                  Episodes <span>List</span>
                </h1>
              </div>
              <ul className="List">
                {watchingEpi.map((item, i) => (
                  <>
                    <li className="list-item" key={item.id}>
                      <NavLink
                        to={
                          "/asian/drama-watch/" + `${length}/` +
                          `${item.id}` +
                          `${image}/`.replace("-poster.png","") +
                          `${country}`
                        }
                      >
                        <div className="image-container">
                        <ImageComponent src={src} title={item.episode} />
                          <span className="tag">{country}</span>
                        </div>
                        <div className="ep-flex">
                          <p>{item.episode}</p>
                        </div>
                      </NavLink>
                    </li>
                  </>
                ))}
              </ul>
              {/* <Episodes /> */}
            </div>

            <div className="right-container">{/* <Recommend /> */}</div>
          </div>
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default AsianWatchingEp;
